module.exports = {
  siteTitle: 'CCO Concierge', // <title>
  manifestName: 'CCO Concierge',
  manifestShortName: 'CCOConcierge', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/cco-concierge/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Completely Mobile!',
  subHeading:
    'Engine Service, Electronics Upgrades, Detailing and Everything Else Under the Sun. On Site, On Time and Completely Hassle-Free. ',

  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com/ccoconcierge',
    },
    // {
    //   icon: 'fa-linkedin-in',
    //   name: 'Linkedin',
    //   url: 'https://linkedin.com/in/theanubhav/',
    // },
    // {
    //   icon: 'fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/onlyanubhav',
    // },
    // {
    //   icon: 'fa-facebook-f',
    //   name: 'Facebook',
    //   url: 'https://facebook.com/theanubhav',
    // },
  ],
  email: 'support@ccoconcierge.com',
  phone: '786-863-5506',
  address: '4658 SW 74th AVE Miami, FL 33155',
};
