import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import '../assets/sass/grayscale.scss';

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: 'CCO Concierge - Mobile Vessel Service',
                },
                {
                  name: 'keywords',
                  content: 'Mercury, Yamaha, Suzuki, Service, Concierge Service, Garmin, Simrad',
                },
              ]}
            >
              {/*<script*/}
              {/*  src="https://d3ey4dbjkt2f6s.cloudfront.net/assets/static_link/work_request_embed_dialog_snippet.js"*/}
              {/*  clienthub_id="fe65677a-b9e0-40fd-82ff-2195043b5b37"*/}
              {/*  form_url="https://clienthub.getjobber.com/client_hubs/fe65677a-b9e0-40fd-82ff-2195043b5b37/public/work_request/embedded_dialog_work_request_form"*/}
              {/*/>*/}
              {/*<link*/}
              {/*  rel="stylesheet"*/}
              {/*  media="screen"*/}
              {/*  href="https://d3ey4dbjkt2f6s.cloudfront.net/assets/external/work_request_embed.css"*/}
              {/*/>*/}
              <html lang="en" />
            </Helmet>
            <div className={'page-top'}>{children}</div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
